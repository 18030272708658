<template>
  <!-- 销售清单 -->
  <div class="supplierManagement" v-loading="loading">
    <div class='globle_border content'>
      <header>
        <el-select v-model="deliveryStatus" placeholder="签收状态" clearable @change="onCurrentPage()">
          <el-option v-for="item in selectData" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input placeholder="请输入发货单号" suffix-icon="el-icon-search" v-model="deliveryNum" :clearable="true"
          @change="onCurrentPage()"></el-input>
        <el-input placeholder="请输入商超名称" suffix-icon="el-icon-search" v-model="shopName" :clearable="true"
          @change="onCurrentPage()"></el-input>
      </header>
      <!-- 表格 -->
      <div class="globle_table tableBox">
        <el-table max-height="540" :data="tableData" style="width: 100%">
          <el-table-column label="序号" min-width="60" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="deliveryNum" label="发货单号" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column prop="purchaseNum" label="采购单号" show-overflow-tooltip min-width="130" align="center">
          </el-table-column>
          <el-table-column prop="shopName" label="商超名称" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column prop="createTime" label="发货时间" show-overflow-tooltip min-width="160" align="center">
          </el-table-column>
          <el-table-column prop="signTime" label="签收时间" show-overflow-tooltip min-width="160" align="center">
          </el-table-column>
          <el-table-column label="签收状态" show-overflow-tooltip min-width="100" align="center">
            <template slot-scope="scope">
              <div>
                {{ selectStatus[scope.row.deliveryStatus] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="查看发货单" show-overflow-tooltip min-width="100" align="center">
            <template slot-scope="scope">
              <el-image v-show="false" :preview-src-list="srcList" ref="previewImg">
              </el-image>
              <div @click="onViewImage(scope.row.deliverImageUrl)" style="cursor: pointer;color: #069BBC;">查看图片
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="查看收货单" show-overflow-tooltip min-width="100" align="center">
            <template slot-scope="scope">
              <el-image v-show="false" :preview-src-list="srcList2" ref="previewNoteImage">
              </el-image>
              <div @click="onReceivingNoteImage(scope.row.receiptImageUrl)" style="cursor: pointer;color: #069BBC;">查看图片
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" fixed="right" min-width="180" align="center">
            <template slot-scope="scope">
              <div style="display: flex;">
                <el-button type="primary" size="small" plain @click="handleCommand(scope.row)"
                  style="margin-right: 10px;">查看</el-button>
                <el-upload class="upload-demo" accept=".png,.jpeg,.jpg" :file-list="fileList" :limit='1'
                  name="multipartFile" :action="$axios.defaults.baseURL + 'attachment/uploadFile'"
                  :on-success="handleSuccess" :before-upload="beforeUploads" list-type="picture"
                  :show-file-list="false">
                  <el-button type="primary" @click="handleChange(scope.row, $event)">上传发货单</el-button>
                </el-upload>
              </div>

            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper, sizes, total" :total="totalCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      loading: false,
      // 表格数据
      tableData: [],
      fileList: [],
      form: {
        imageUrl: '', //图片路径
        fileId: '', //图片id
        deliveryId: '', //发货单id
      },
      // // 发货状态
      selectStatus: {
        2: '待签收',
        3: '已签收'
      },
      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50],
      totalCount: 0, // 总记录数据
      // 发货状态
      selectData: [{
        value: '2',
        label: '待签收'
      }, {
        value: '3',
        label: '已签收'
      }],
      deliveryNum: '', //发货单编号
      deliveryStatus: '', //发货状态
      shopName: '', //商超名称
      srcList: [], //大图预览
      srcList2: [], //大图预览
    }
  },
  created() {
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("productList"))
    this.deliveryStatus = obj?.deliveryStatus
    this.deliveryNum = obj?.deliveryNum
    this.shopName = obj?.shopName
    this.currentPage = obj?.currentPage
    this.$saveOrUpdateCache("productList", {})
    // 获取列表
    this.getRequest()
  },
  methods: {
    // 请求列表
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.sendDeliveryList, {
        params: {
          deliveryStatus: this.deliveryStatus || null, //状态：2已发货，3已完成
          deliveryNum: this.deliveryNum || null, //发货单编号
          shopName: this.shopName || null, //商超名称
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        const {
          code,
          result
        } = res.data
        if (code == 100) {
          this.totalCount = result.totalCount
          this.tableData = result.list
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })

    },
    // 点击按钮获取当前数据
    handleChange(row) {
      // console.log(event);
      // event.stopPropagation(); // 阻止事件冒泡
      this.form.deliveryId = row.deliveryId; //发货单id
    },
    // 查看发货单图片
    onViewImage(imageUrl) {
      if (imageUrl) {
        this.srcList = [imageUrl];
        this.$refs.previewImg.showViewer = true;
      } else {
        this.$message.error("未上传发货单图片，请上传后再查看!");
      }
    },
    // 查看收货单图片
    onReceivingNoteImage(imageUrl) {
      if (imageUrl) {
        this.srcList = [imageUrl];
        this.$refs.previewNoteImage.showViewer = true;
      } else {
        this.$message.error("未上传收货单图片，请联系收货人确认收货后上传再查看!");
      }
    },
    // 上传中转成功回调
    handleSuccess(res) {
      if (res.code == 100) {
        this.form.fileId = res.result.fileId;
        this.form.imageUrl = res.result.fileUrl;
        this.onSuccess()
      } else {
        this.$message({
          showClose: true,
          message: res.desc,
          type: "error",
        });
      }
    },
    // 图片上传前检查
    beforeUploads(file) {
      const isJPG = file.type === "image/jpeg" || "image/jpg" || "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error("图文只支持jpg、png、jpeg，请调整格式再上传!");
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      }
      return isJPG && isLt5M;
    },
    onSuccess() {
      this.$axios.post(this.$api.sendUpload,
        this.form
      ).then((res) => {
        const {
          code
        } = res.data
        if (code == 100) {
          this.$message({
            message: '恭喜你，上传成功！',
            type: 'success'
          });
          this.onCurrentPage()
        }
      }).catch((err) => {
        console.log(err);
      })
    },

    // 分页器
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getRequest()
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val
      this.tableData = []
      this.getRequest()
    },
    // 点击跳转
    handleCommand(row) {
      let obj = {
        deliveryStatus: this.deliveryStatus,
        deliveryNum: this.deliveryNum,
        shopName: this.shopName,
        currentPage: this.currentPage,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("productList", obj)

      this.$router.push({
        name: 'completeAllDet',
        query: {
          deliveryId: row.deliveryId
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.supplierManagement {
  .content {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1.5;

    }

    header {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .el-input,
      .el-select {
        width: 180px;
        margin-left: 10px;
        margin-bottom: 20px;
      }

    }
  }

  .tableBox {
    overflow: auto;

    :deep .el-table--group::after,
    .el-table--border::after,
    .el-table::before {
      content: '';
      position: absolute;
      background-color: #EBEEF5;
      opacity: 0;
    }

    :deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 6px;
      /*滚动条宽度*/
      height: 4px;
      /*滚动条高度*/
    }

  }

  // 操作下拉框内容
  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;

  }

}
</style>
